<template>
<div style="margin-top: 80px;">
    <BasePageBreadCrumb :pages="pages" title="novo kit" :menu="menu"/>
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="card card-bg-trans">
                <div class="card-body pt-4 px-0">
                    <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                        <div class="form-body">
                            <div class="row"> 
                                 <div class="col-12">
                                    <div class="form-group">
                                          <label>Tipologias vínculadas </label>
                                         <h6 class="text-truncate empreendi"><div v-for="(kit) in kit.tipologies_name" :key="kit.id"><b>{{ kit.code }} - {{ kit.name }}</b> </div></h6>
                                    </div>
                                </div>
                                <div class="col-12 col-md-3 col-lg-3">
                                    <div class="form-group">
                                        <label>Categorias</label>
                                        <b-form-select class="custom-select mr-sm-2" :options="categories" v-model="kit.category_id" name="category" v-validate="'required'" :class="{'is-invalid': errors.has('category')}">
                                        </b-form-select>
                                    </div>
                                </div>
                                <div v-if="personalization_theme == 'tema1'" class="col-12 col-md-3 col-lg-3">
                                    <div class="form-group">
                                        <label>GALERIA DE FOTOS</label>
                                        <b-form-select class="custom-select mr-sm-2" :options="galleries" v-model="kit.gallery_id" name="gallery" v-validate="'required'" :class="{'is-invalid': errors.has('gallery')}">
                                        </b-form-select>
                                    </div>
                                </div> 
                                <div v-if="personalization_theme == 'tema1'" class="col-12 col-md-3 col-lg-3">
                                    <div class="form-group">
                                        <label>KIT DE DECORAÇÃO</label>
                                        <select class="custom-select mr-sm-2" v-model="kit.is_decoration" name="is_decoration" v-validate="'required'" :class="{'is-invalid': errors.has('is_decoration')}">
                                            <option :value="null">Selecione</option>
                                            <option :value="true">Sim</option>
                                            <option :value="false">Não</option>
                                        </select>
                                    </div>
                                </div>
                                <div v-if="personalization_theme == 'tema1'" class="col-12 col-md-3 col-lg-3">
                                    <div class="form-group">
                                        <label>DECORAÇÃO</label>
                                        <b-form-select class="custom-select mr-sm-2" :options="decorations" v-model="kit.decoration_kit_id">
                                        </b-form-select>
                                    </div> 
                                </div>
                                
                                <div v-if="personalization_theme == 'tema1'" class="col-12 col-md-3 col-lg-3">
                                    <div class="form-group">
                                        <label>MODO DE EXIBIÇÃO</label>
                                        <select class="custom-select mr-sm-2" v-model="kit.exhibition" name="exhibition" v-validate="'required'" :class="{'is-invalid': errors.has('exhibition')}">
                                            <option :value="null">Selecione</option>
                                            <option value="LIST">Lista</option>
                                            <option value="GRADE">Grade</option>
                                        </select>
                                    </div>
                                </div> 
                                <div v-if="personalization_theme == 'tema1'" class="col-12 col-md-3 col-lg-3">
                                    <div class="form-group">
                                        <label>TIPO</label>
                                        <select class="custom-select mr-sm-2" v-model="kit.type" name="type" v-validate="'required'" :class="{'is-invalid': errors.has('type')}">
                                            <option :value="null">Selecione</option>
                                            <option value="MAIN">Principal</option>
                                            <option value="SECONDARY">Secundário</option>
                                        </select>
                                    </div>
                                </div> 
                                <div class="col-12 col-md-3 col-lg-3">
                                    <div class="form-group">
                                        <label>ORDEM</label>
                                        <b-form-select class="custom-select mr-sm-2" :options="orders" v-model="kit.order" name="order" v-validate="'required'" :class="{'is-invalid': errors.has('order')}">
                                        </b-form-select>
                                    </div>
                                </div>
                                 <div  class="col-12 col-md-3 col-lg-3" v-if="kit.type == 'MAIN' && personalization_theme == 'tema1'">
                                    <div class="form-group">
                                        <label>LIBERAR SECUNDÁRIO</label>
                                        <select class="custom-select mr-sm-2" v-model="kit.free_secondary" name="is_decoration" v-validate="'required'" :class="{'is-invalid': errors.has('is_decoration')}">
                                            <option :value="null">Selecione</option>
                                            <option :value="true">Sim</option>
                                            <option :value="false">Não</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-10 col-lg-10 pt-1">
                                    <div class="form-group">
                                        <label>NOME DO KIT</label>
                                        <input type="text" id="inputSuccess1" class="form-control" placeholder="Ex.: Kit_multimidia" v-model="kit.name" name="name" v-validate="'required'" :class="{'is-invalid': errors.has('name')}">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Imagem do kit, perspectiva ou ambiente</label>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend w-100">
                                                <span class="input-group-text">Upload</span>
                                                <div class="custom-file">
                                                    <input required type="file" multiple class="custom-file-input" @change="setFile" id="inputGroupFile01">
                                                    <label class="custom-file-label" for="inputGroupFile01">{{kit.image_name}}</label>
                                                </div>   
                                            </div> 
                                        </div>
                                    </div>                                                 
                                </div>
                                <div v-if="personalization_theme == 'tema1'" class="col-12 col-md-2 col-lg-2">
                                    <div class="form-group">
                                        <label>MASTER KIT</label>
                                        <b-form-checkbox v-model="kit.master_kit" name="check-button" switch></b-form-checkbox>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 col-lg-12 pt-1">
                                    <div class="form-group">
                                        <label>Descrição </label>
                                        <textarea class="form-control" rows="6" placeholder="" v-model="kit.description" name="description" v-validate="'required'" :class="{'is-invalid': errors.has('description')}"></textarea>
                                        <small id="textHelp" class="form-text text-muted">Informe detalhes sobre o kit.</small>
                                    </div>
                                </div>
                                <div class="col-12 pb-2">
                                    <BaseEnterpriseBox v-model="kit.enterprise_id" />
                                </div>
                                <div class="col-12 pb-2">
                                    <BaseTipologyBox v-model="kit.tipologies" :enterprise_id="kit.enterprise_id" :multiple="true" />
                                </div>
                                <div class="col-12 pb-2">
                                    <BaseProductBox v-model="kit.products" />
                                </div>
                                <div class="col-12 col-md-12 col-lg-12 pt-1">
                                    <div class="form-group">
                                        <label>Valor total</label>
                                        <input type="text" id="inputSuccess1" class="form-control" v-model="kit.price" placeholder="R$ 23.000,90" v-money="money">
                                    </div>
                                </div>
                                <!-- <div class="col-12 pb-2">
                                    <label>Imagem </label>
                                </div>
                                <vue-upload-multiple-image
                                    @upload-success="uploadImageSuccess"
                                    @before-remove="beforeRemove"
                                    @edit-image="editImage"
                                    :data-images="kit.images"
                                    idUpload="myIdUpload"
                                    editUpload="myIdEdit"
                                    dragText="Clique ou arraste uma image aqui"
                                    browseText="Procurar imagem"
                                    :showPrimary="false"
                                    :showEdit="false"
                                    :multiple="true"
                                    :maxImage="10">
                                </vue-upload-multiple-image> -->
                            </div>
                        </div>
                    
                        <div class="form-actions">
                            <div class="text-right">
                                <button type="reset" class="btn btn-dark">Cancelar</button>
                                <button type="submit" class="btn btn-info ml-2">{{ id ? 'Salvar' : 'Cadastrar' }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import KitService from "@/services/resources/KitService";
import KitServiceConfig from "@/services/resources/KitConfigService";
import ThemeService from "@/services/resources/ThemeService";

import VueUploadMultipleImage from "vue-upload-multiple-image";
import {VMoney} from 'v-money'
const service = KitService.build();
let serviceTheme = ThemeService.build();
const serviceConfig = KitServiceConfig.build();

export default {
   directives: { money: VMoney },
   components: { VueUploadMultipleImage },
   data(){
       return{
           money: {
            decimal: ',',
            thousands: '.',
            precision: 2
           },
           kit:{
               name: null,
               description: null,
               category_id: null,
               gallery_id: null,
               exhibition: null,
               free_secondary: null,
               order: null,
               image: "",
               image_name: "",
               is_decoration: null,
               products: [],
               tipologies: [],
               price: null,
               type: null,
               images: [],
               decoration_kit_id: null,
               master_kit: false,
           },
           categories: [],
           galleries: [],
           tipologies: [],
           decorations: [],
           orders: [{text: 'Selecione', value: null}],
           pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'novo kit',
                    to: 'KitNew'
                }
            ],
            menu:[
                {
                    name: 'Novo kit',
                    to: 'KitNew'
                },
                {
                    name: 'Kit',
                    to: 'Kit'
                }
            ],
            id: null
       }
   },
   methods:{
       toFloat(value){
           return parseFloat(String(value).replace('.','').replace(',', '.'));
       },
       uploadImageSuccess(formData, index, fileList) {
            this.kit.images = fileList;
       },
       beforeRemove(index, done, fileList) {
            var r = confirm("Remover imagem?");
            if (r == true) {
                this.kit.images.splice(index, 1);
                done();
            }
       },
       fetchTheme() {
            let data = {
                domain: window.location.hostname.split("app.")[1]
            };

            serviceTheme
                .search(data)
                .then(resp => {
                    resp.forEach(element => {
                        if (element.key ==  "SETTINGS_APTO_PERSONALIZATION_THEME") {                         
                            this.personalization_theme = element.value;
                        }
                    });
                });
        },
       editImage(formData, index, fileList) {
            this.kit.images = fileList;
       },
       setFile(eve) {  
            var reader = new FileReader();
            reader.onload = function (eve2) {              
              this.kit["image"] =  eve2.target.result;
            }.bind(this)          
            reader.readAsDataURL(eve.target.files[0]);
            this.kit.image_name = eve.target.files[0].name           
        },
       save(){

        this.$validator.validateAll().then((result) => {
            if (result) {
                let kit = Object.assign({}, this.kit)
                kit.price = this.toFloat(kit.price);

                if(this.id){
                    kit.id = this.id;

                    service
                    .update(kit)
                    .then(resp => {
                    this.$bvToast.toast('Seu kit foi atualizado com sucesso!', {
                        title: 'kit atualizado',
                        autoHideDelay: 5000,
                        type: 'success'
                        })
                    })
                    .catch(err => {
                    })
                }else{
                    service
                    .create(kit)
                    .then(resp => {
                    this.$bvToast.toast('Seu kit foi criado com sucesso!', {
                        title: 'Kit criado',
                        autoHideDelay: 5000,
                        type: 'success'
                        })
                    })
                    .catch(err => {
                    })
                }

          
            }
        });
       },
       fetchKit(){

            if(!this.id){
                return;
            }

            let data = {
                id: this.id
            }

            service
            .read(data)
            .then(resp => {
                this.kit.name = resp.name;
                this.kit.type = resp.type;
                this.kit.description = resp.description;
                this.kit.price = resp.price * 100;
                this.kit.category_id = resp.category_id;
                this.kit.enterprise_id = resp.enterprise_id;
                this.kit.exhibition = resp.exhibition;
                this.kit.gallery_id = resp.gallery_id;
                this.kit.decoration_kit_id = resp.decoration_kit_id;
                this.kit.is_decoration = resp.is_decoration == 1;
                this.kit.free_secondary = resp.free_secondary == 1;
                this.kit.order = resp.order;
                this.kit.master_kit = resp.master_kit;
                this.kit.products = resp.products.map(elem => {
                    return elem.product_id
                });
                this.kit.tipologies = resp.tipologies.map(elem => {
                    return elem.tipology_id
                });
                this.kit.images = resp.images;
                this.kit.tipologies_name = resp.tipologies_name;


            })
            .catch(err => {
                console.log(err)
            })
       },
       fetchConfig(){
           this.categories = [{text: 'Selecione', value: null}];
           this.galleries = [{text: 'Selecione', value: null}];

            serviceConfig
            .search()
            .then(resp => {
                resp.forEach(element => {
                    if(element.type == 'CATEGORY'){
                        this.categories.push({
                            value: element.id,
                            text: element.name
                        });
                    }else if(element.type == 'GALLERY'){
                        this.galleries.push({
                            value: element.id,
                            text: element.name
                        });
                    }
                });
            })
            .catch(err => {
                console.log(err)
            })

       },
       fetchDecoration(){
           this.decorations = [{text: 'Selecione', value: null}];

            service
            .search()
            .then(resp => {
                resp.forEach(element => {
                    this.decorations.push({
                        value: element.id,
                        text: element.name + ' - ' + element.tipologies.map(elem => elem.tipology.name + ' - ' + elem.tipology.code).join(' | ')
                    });
                });
            })
            .catch(err => {
                console.log(err)
            })

       },
   },
   mounted(){
    console.log(this.$store.getters);
       this.id = this.$route.params.id;
       this.fetchKit();
       this.fetchTheme()
       this.fetchConfig();
       this.fetchDecoration();
       this.orders = this.orders.concat(new Array(100).fill().map((e,j) => {
            return {value: j + 1, text: j + 1}
       }));
   }
}
</script>